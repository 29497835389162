import { useState, useEffect } from 'react';
import Base from '../components/Base.js';

import { getDateOnly, getTimeOfDay } from '../util.js';

const LIVESTREAM_END = process.env.REACT_APP_LIVESTREAM_END;

function Schedule() {
    const [days, setDays] = useState(null);
    const [schedule, setSchedule] = useState(null);
    const [useLocalTime, setUseLocalTime] = useState(false);

    async function loadDays(scheduleData, useLocalTime) {
        const days_ = [getDateOnly(scheduleData[0].start, (useLocalTime ? 0 : -14400000))];
        for (const activity of scheduleData) {
            const d = getDateOnly(activity.start, (useLocalTime ? 0 : -14400000));
            if (d.getTime() !== days_[days_.length - 1].getTime())
                days_.push(d);
        }
        setDays(days_);
    }

    async function loadSchedule() {
        const res = await fetch('/api/schedule');
        const scheduleData = await res.json();
        scheduleData.sort((a, b) => (new Date(a.start) - new Date(b.start)));
        loadDays(scheduleData);
        setSchedule(scheduleData);
    }

    useEffect(() => {
        loadSchedule();
    }, []);

    function formatDate(date) {
        date = new Date(date);
        const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'][date.getMonth()];
        const day = date.getDate();
        const time = date.toLocaleTimeString().replace(':00 ', ' ');
        return `${month} ${day} ${time}`;
    }

    if (LIVESTREAM_END < Date.now() / 1000)
        return <Base>
            <center><h2>Livestream schedule</h2></center>
            The current event has ended. Catch us next time!
        </Base>

    if (!schedule || !days)
        return <Base>Loading schedule...</Base>;

    return <Base>
        { useLocalTime
            ? <p>The current schedule is tentative and subject to change. All times are displayed in your local timezone. To view the schedule on Boston time, <a href="javascript:;" onClick={() => { loadDays(schedule, false); setUseLocalTime(false); }}>click here</a>.</p>
            : <p>The current schedule is tentative and subject to change. All times are local to Boston, MA. To display times in your local timezone, <a href="javascript:;" onClick={() => { loadDays(schedule, true); setUseLocalTime(true); }}>click here</a>.</p>
        }
        <table style={{ width: '100%' }}>
            {days.filter(day => new Date(day).getTime() > 1000000000000).map(day => <>
                <tr><td colspan="2" style={{ fontSize: "1.3em", padding: "0.5em 0 0.3em 0", textAlign: "center" }}>{day.toDateString()}</td></tr>
                {schedule.filter(x => getDateOnly(x.start, (useLocalTime ? 0 : -14400000)).getTime() === day.getTime()).map((block, i) => <>
                    <tr style={i % 2 ? { background: '#DBB4DB' } : { background: '#FBD4FB' }}>
                        <td style={{ padding: '0.5em 0.5em', width: '25vw', maxWidth: '11em', verticalAlign: 'top' }}>{getTimeOfDay(block.start, (useLocalTime ? 0 : -14400000))} - {getTimeOfDay(block.end, (useLocalTime ? 0 : -14400000))}</td>
                        <td style={{ padding: '0.2em 0.5em' }}><table style={{ cellPadding: 0, cellSpacing: 0 }}>
                            <tr><td style={{ fontWeight: 'bold' }}>{block.events}</td></tr>
                            { block.emcees
                                ? <tr><td style={{ fontSize: '0.7em' }}>Hosted by: {block.emcees}</td></tr>
                                : null
                            }
                        </table></td>
                    </tr>
                    { block.description
                        ? <tr style={{ background: '#FFECFF' }}>
                            <td colspan="2" style={{ padding: '0.5em 6vw 1em 6vw', textAlign: 'center', fontSize: '0.9em' }}>{block.description}</td>
                        </tr>
                        : null
                    }
                </>)}
            </>)}
        </table>
        <hr style={{ margin: "3em 0" }} />
        <p>The following activities will all be part of the schedule, but have not yet been given their individual timeslots:</p>
        <table style={{ width: '100%' }}>
            {days.filter(day => new Date(day).getTime() < 1000000000000).map(day => <>
                {schedule.filter(x => getDateOnly(x.start, (useLocalTime ? 0 : -14400000)).getTime() === day.getTime()).map((block, i) => <>
                    <tr style={i % 2 ? { background: '#DBB4DB' } : { background: '#FBD4FB' }}>
                        <td style={{ padding: '0.2em 0.5em' }}><table style={{ cellPadding: 0, cellSpacing: 0 }}>
                            <tr><td style={{ fontWeight: 'bold' }}>{block.events}</td></tr>
                            {block.emcees
                                ? <tr><td style={{ fontSize: '0.7em' }}>Hosted by: {block.emcees}</td></tr>
                                : null
                            }
                        </table></td>
                    </tr>
                    {block.description
                        ? <tr style={{ background: '#FFECFF' }}>
                            <td colspan="2" style={{ padding: '0.5em 6vw 1em 6vw', textAlign: 'center', fontSize: '0.9em' }}>{block.description}</td>
                        </tr>
                        : null
                    }
                </>)}
            </>)}
        </table>
    </Base>
    /*
        <center>
            <p>All dates/times converted to your local timezone.</p>
        </center>
        */
}

export default Schedule;
