import React, { useState, useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import RelativeTime from '@yaireo/relative-time';

import background from '../images/background.png';
import styles from './DonateCard.module.css';

import { createOrder, onApprove } from './PayPal.js';

const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

function DonateCard()
{
    const [state, setState] = useState('browsing'); // browsing, checkout, submitted, approved, error
    const [displayName, setDisplayName] = useState('');
    const [amount, setAmount] = useState(0);
    const [message, setMessage] = useState('');
    const [extraInfo, setExtraInfo] = useState({});
    const [incentives, setIncentives] = useState(null);
    const [chosenIncentiveID, setChosenIncentiveID] = useState(null);
    const [error, setError] = useState(null);

    const loadIncentives = async () => {
        const res = await fetch('/api/incentives');
        let incentivesRaw = await res.json();
        incentivesRaw.forEach(incentive => {
            if ('goal' in incentive) {
                if (!('raised' in incentive))
                    incentive.raised = 0;
                incentive.progress = 100 * incentive.raised / incentive.goal;
            }
        });
        incentivesRaw.filter(x => (x.type === 'bidwar' || x.type === 'selection')).forEach(category => {
            const options = incentivesRaw.filter(x => x.id.startsWith(category.id + ':'));
            incentivesRaw = incentivesRaw.filter(x => !options.includes(x));
            category.category = true;
            category.options = options;
            category.expanded = false;
            options.forEach(option => {
                option.categoryOption = true;
                option.hidden = true;
                if (category.min_donation && (!option.min_donation || category.min_donation > option.min_donation)) {
                    option.min_donation = category.min_donation;
                    option.hide_min_donation = true;
                }
            });
            options.sort((a, b) => {
                if (a.id < b.id)
                    return -1;
                if (a.id > b.id)
                    return 1;
                return 0;
            });
        });
        incentivesRaw.filter(x => x.type === 'bidwar').forEach(bidwar => {
            const options = bidwar.options;
            options.forEach(option => {
                option.bidwarOption = true;
                if (!('raised' in option))
                    option.raised = 0;
            });
            options.sort((a, b) => b.raised - a.raised);
            const highestBid = Math.max(...(options.map(x => x.raised)));
            options.forEach(option => {
                option.progress = 100 * option.raised / highestBid;
            });
        });
        incentivesRaw.sort((a, b) => {
            // sort by closest expiration first
            const aExpires = a.expires ? new Date(a.expires) : new Date('9999-01-01T00:00:00.000Z');
            const bExpires = b.expires ? new Date(b.expires) : new Date('9999-01-01T00:00:00.000Z');
            if (aExpires < bExpires)
                return -1;
            if (aExpires > bExpires)
                return 1;
            // sort by id thereafter
            if (a.id < b.id)
                return -1;
            if (a.id > b.id)
                return 1;
            return 0;
        });
        let processedIncentives = [];
        incentivesRaw.forEach(incentive => {
            processedIncentives.push(incentive);
            if (incentive.category)
                processedIncentives.push(...incentive.options);
        });
        setIncentives(processedIncentives);
    }

    useEffect(() => {
        document.body.style.backgroundImage = `url(${background})`;

        return () => {
            document.body.style.backgroundImage = null;
        }
    });

    useEffect(() => {
        if (!incentives)
            loadIncentives();
    }, [incentives])

    const incentiveClicked = (incentive) => {
        setChosenIncentiveID(incentive.id);
    }

    const categoryToggled = (category) => {
        category.options.forEach(option => { option.hidden = category.expanded });
        category.expanded = !(category.expanded);
        setIncentives(structuredClone(incentives));
    }

    const okToCheckout = () => {
        if (amount <= 0)
            return false;
        const incentive = incentives.find(x => x.id === chosenIncentiveID);
        if (incentive && 'min_donation' in incentive && amount < incentive.min_donation)
            return false;
        if (incentive && incentive.info_needed)
            for (const info_need of incentive.info_needed)
                if (!extraInfo[info_need])
                    return false;
        return true;
    }

    const checkout = () => {
        if (!okToCheckout())
            return;
        setState('checkout');
    }

    const userCreateOrder = async (data, actions) => {
        const res = await createOrder(data, {
            name: displayName,
            amount: amount,
            message: message,
            extraInfo: extraInfo,
            incentiveID: chosenIncentiveID,
        });
        if (res.status === 201)
        {
            return await res.json().then((order) => order.id);
        }
        const resJson = await res.json();
        setState('error');
        setError(resJson.error);
    }

    const userApprove = async (data, actions) => {
        setState('submitted');
        const res = await onApprove(data);
        const resJson = await res.json();
        if (res.status === 201)
        {
            setState('approved');
            return resJson;
        }
        setState('error');
        setError(resJson.message);
    }

    function renderIncentive(incentive) {
        const relativeTime = new RelativeTime();

        // min donation dollar amount turns red if not enough
        if (incentive.hidden === true || incentive.disabled === true)
            return null;
        let minDonationStlye = null;
        if ('min_donation' in incentive && amount < incentive.min_donation)
            minDonationStlye = styles.red;

        if (incentive.category)
            return <div className={styles.incentive + ' ' + styles.flexbox} key={incentive.id} onClick={() => categoryToggled(incentive)}>
                <div className={styles.incentiveBack} />
                <div style={{ padding: '0.5em' }}>
                    <div className={styles.incentiveTitle}>{incentive.title}</div>
                    {incentive.description
                        ? <div className={styles.incentiveDescription}>{incentive.description}</div>
                        : null
                    }
                    {incentive.min_donation
                        ? <div className={styles.incentiveVotes}>min donation: <span className={minDonationStlye}>${incentive.min_donation.toFixed(2)}</span></div>
                        : null
                    }
                    <div className={styles.incentiveVotes}>Click to {incentive.expanded ? 'hide' : 'show'} options</div>
                    {incentive.type === 'bidwar'
                        ? <div className={styles.incentiveVotes}>Voting closes {relativeTime.from(new Date(incentive.expires))}</div>
                        : <div className={styles.incentiveVotes}>Ends {relativeTime.from(new Date(incentive.expires))}</div>
                    }
                </div>
            </div>;

        return <div className={styles.incentive + ' ' + styles.flexbox} key={incentive.id}>
            <div className={styles.incentiveBack} />
            <div className={styles.incentiveProgress} style={{ width: incentive.progress + '%' }} />
            <div className={styles.flexbox} style={{ width: '100%', height: '100%' }} onClick={() => incentiveClicked(incentive)}>
                {incentive.categoryOption
                    ? <div style={{ width: "2em" }} />
                    : null
                }
                <div className={styles.incentiveSelectButton}><input type="radio" name="pickapuzzle" checked={incentive.id === chosenIncentiveID} readOnly></input></div>
                {incentive.type === 'puzzle'
                    ? <div><img src={incentive.image} className={styles.incentiveImage} alt="img" /></div>
                    : null
                }
                <div style={{ padding: '0.5em 0' }}>
                    <div className={styles.incentiveTitle}>{incentive.title ? incentive.title : incentive.name}</div>
                    {incentive.author
                        ? <div className={styles.incentiveAuthor}>by {incentive.author}</div>
                        : null
                    }
                    {incentive.description
                        ? <div className={styles.incentiveDescription}>{incentive.description}</div>
                        : null
                    }
                    {incentive.min_donation && !incentive.hide_min_donation
                        ? <div className={styles.incentiveVotes}>min donation: <span className={minDonationStlye}>${incentive.min_donation.toFixed(2)}</span></div>
                        : null
                    }
                    {incentive.goal
                        ? <div className={styles.incentiveVotes}>progress: ${incentive.raised.toFixed(2)} / ${incentive.goal.toFixed(2)}</div>
                        : null
                    }
                    {incentive.bidwarOption
                        ? <div className={styles.incentiveVotes}>current votes: ${incentive.raised.toFixed(2)}</div>
                        : null
                    }
                    {incentive.expires
                        ? <div className={styles.incentiveVotes}>Ends {relativeTime.from(new Date(incentive.expires))}</div>
                        : null
                    }
                </div>
            </div>
            {incentive.link
                ? <a className={styles.flexbox + ' ' + styles.incentiveLink} href={incentive.link} target="_blank" rel="noreferrer"><div>&#9654;</div></a>
                : null
            }
        </div>;
    }

    if (!incentives)
        return 'Loading';

    const incentive = incentives.find(x => x.id === chosenIncentiveID);
    let display = null;
    switch (state) {
        case 'browsing':
            display = <div className={styles.browsingContainer}><form>
                <div style={{ maxHeight: '50vh', overflow: 'auto' }}>
                    {renderIncentive({ id: null, title: 'None' })}
                    {incentives.map(renderIncentive)}
                </div>
                <center>Looking for the options for in-puzzle effects? Try <a href="/donate/bits">Donate with Twitch Bits</a> instead!</center><br/>
                <label>Name to be displayed on stream: </label><input type="text" value={displayName} onChange={(event) => { setDisplayName(event.target.value); }}></input><br/>
                <label>Donation amount: &nbsp;$&nbsp;</label><input type="number" value={amount} min={0} onChange={(event) => { setAmount(event.target.value); }}></input><br/>
                <label>Donation message: </label><br/>
                <textarea rows="4" style={{ width: "99%" }} value={message} onChange={(event) => { setMessage(event.target.value); }} /><br/>
                {incentive && incentive.info_needed
                    ? <>
                        {incentive.info_needed.map((x, i) => <div key={incentive.id + i}>
                            <label>{x}</label>
                            <br />
                            <textarea rows="3" style={{ width: "99%" }} value={extraInfo[x]} onChange={(event) => {
                                setExtraInfo({ ...extraInfo, [x]: event.target.value });
                            }} />
                            <br/>
                        </div>)}
                    </>
                    : null
                }
                <div className={styles.flexbox}>
                    <button onClick={checkout} disabled={!okToCheckout()}>Checkout</button>
                    <div style={{ marginLeft: 'auto' }}>
                        <a href="/policy" target="_blank" rel="noreferrer">View policies</a>
                    </div>
                </div>
                {amount > 0 && amount < 1
                    ? <p>&#10071; For small donations, we encourage <a href="/donate/bits">donating with Bits</a>, to help us save on processing fees and ensure that more of your cash goes to the event!</p>
                    : null
                }
            </form></div>
            break;
        case 'checkout':
            let incentiveString;
            if (!incentive)
                incentiveString = 'None';
            else if (incentive.type === 'puzzle')
                incentiveString = `"${incentive.name}" by ${incentive.author}`
            else
                incentiveString = incentive.title;
            if (incentive && incentive.bidwarOption)
                incentiveString = 'Bidding for ' + incentiveString;
            display = <center>
                <div className={styles.orderSummary}>
                    <b>Display name:</b> {displayName}<br/>
                    <b>Donation amount:</b> ${Number(amount).toFixed(2)}<br/>
                    <b>Donation message:</b> {message}<br/>
                    {incentive && incentive.info_needed
                        ? <><b>Additional info:</b> {Object.values(extraInfo).join('; ')}<br/></>
                        : null
                    }
                    <b>Incentive chosen:</b> {incentiveString}
                </div>
                <div className={styles.paypalButtons}>
                    <PayPalButtons
                        createOrder={userCreateOrder}
                        options={{ shippingPreference: 'NO_SHIPPING' }}
                        onApprove={userApprove}
                        onSuccess={(details) => { console.log('success') }}
                        catchError={(err) => { console.log('catcherror') }}
                        onError={(err) => { setState('error'); setError(err.message); }}
                    />
                </div>
                <button className={styles.backButton} onClick={() => { setState('browsing'); }}>Go Back</button>
            </center>
            break;
        case 'submitted':
            display = <div>Waiting for server...</div>
            break;
        case 'approved':
            display = <div className={styles.successContainer}>Your donation has been received!
                Please allow some time for it to appear on stream.</div>
            break;
        case 'error':
            display = <div className={styles.errorContainer}>
                An error occured: {error ? error : 'Unknown error.'}
                <br/>
                If the problem persists, try <a href="https://paypal.me/sudokucon?country.x=US&locale.x=en_US" style={{ textDecoration: 'underline' }}>donating directly</a>.</div>
            break;
        default:
            display = 'error';
    }
    return <PayPalScriptProvider options={{ clientId: PAYPAL_CLIENT_ID }}>{display}</PayPalScriptProvider>;
}

export default DonateCard;
