import { TwitchPlayer } from 'react-twitch-embed';

import Base from '../components/Base.js';
import styles from './Home.module.css';

import ad_dimono from '../images/sponsors/TidingsOfMinos.png';
import ad_james from '../images/sponsors/ArtisanalSudoku.png';

export default function Livestream()
{
    return <Base>
        <p>In addition to being an in-person convention, SudokuCon is committed to showcasing the new or rarely-seen side of the sudoku world:
            <ul>
                <li>Puzzles that break the mold in some way and creative new ways to solve existing puzzles.</li>
                <li>Puzzle-adjacent activities that aren't solving or setting at all, but still tickle the same nerves, like trivia games and adversarial setting.</li>
                <li>Pulling out the elusive masters like Philip, Qodec, Tantan, Prasanna, etc. whom you don't see streaming week after week, and giving them a space to shine once or twice a year.</li>
            </ul>
            We also do a yearly 24-hour fundraiser, featuring several diverse and innovative blocks hosted by a myriad of streamers. The streams may be over, the highlights and puzzles that came out of them will always be available:
            <ul>
                <li><a href="/stream2023">2023 Livestream Fundraiser</a></li>
                <li><a href="/stream2024">2024 Livestream and Ticket Sales Grand Opening</a></li>
            </ul>
            Find all of this, and more, throughout the year on our Twitch channel:
        </p>
        <div className={styles.twitchPlayer}>
            <TwitchPlayer
                channel="SudokuCon"
                width="100%"
                height="100%"
                onOnline={() => { }}
                onOffline={() => { }}
            />
        </div>
    </Base>
}
