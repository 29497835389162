import { TwitchPlayer } from 'react-twitch-embed';

import Base from '../components/Base.js';
import styles from './Home.module.css';
import logoYouTube from '../images/socials/yt_icon_rgb.png';

import ad_dimono from '../images/sponsors/TidingsOfMinos.png';
import ad_james from '../images/sponsors/ArtisanalSudoku.png';

const videos = [
    { title: 'Interview with Lumos & Chaos, Student Puzzlers', ytid: '7R5CLy9kqXc', embed: 'https://www.youtube.com/embed/7R5CLy9kqXc?si=WwzUDOsN7P2rw0dY' },
    { title: 'Talking puzzles with FullDeck, Missingafewcards, and students', ytid: 'kVk8aN_D0Wo', embed: 'https://www.youtube.com/embed/kVk8aN_D0Wo?si=ppbs5bbw0NVpK8sF' },
    { title: 'Solving with James Sinclair', ytid: 'HMjpX5HsPuE', embed: 'https://www.youtube.com/embed/HMjpX5HsPuE?si=E-kBFta_Koz86S0h' },
    { title: 'Sudoku Solving with Students', ytid: 'QZExDlfUCiM', embed: 'https://www.youtube.com/embed/QZExDlfUCiM?si=Hb-U6-ECdVWsVr-y' },
    { title: 'A Visit with BremSter', ytid: 'tq5Wu1dW1us', embed: 'https://www.youtube.com/embed/tq5Wu1dW1us?si=Xt_1MBflGCbrmrc1' },
    { title: 'Trivia and Pairdoku Setting with GAS and Friends', ytid: 'umvxSqPSqZE', embed: 'https://www.youtube.com/embed/umvxSqPSqZE?si=0x9YyPNQH7cVB4i5' },
    { title: 'Mutually Assured Deduction', ytid: 'N3TLx4rIKjc', embed: 'https://www.youtube.com/embed/N3TLx4rIKjc?si=4TkyNYP1p7NQYYIw' },
    { title: 'Prasanna solves "The Right Way"', ytid: 'WMWNxFda2pk', embed: 'https://www.youtube.com/embed/WMWNxFda2pk?si=u00NuvtMSmXT1T4e' },
    { title: 'Speed Solving with Tantan Dai', ytid: 'flCEwyxjDBg', embed: 'https://www.youtube.com/embed/flCEwyxjDBg?si=c9BcJ-sqWfi-FZYl' },
    { title: 'Gattai Solving with Kodra', ytid: 'K4r65-GI9m4', embed: 'https://www.youtube.com/embed/K4r65-GI9m4?si=EYJ1Rew8w73es2kP' },
    { title: 'Kouchoku with Caren, Jonn, and Wessel', ytid: '0vfuADuOHEA', embed: 'https://www.youtube.com/embed/0vfuADuOHEA?si=FgpNyWx1zOIXmJwY' },
    { title: 'Interviews with Qodec and Sven', ytid: 'icJZg9WsFyo', embed: 'https://www.youtube.com/embed/icJZg9WsFyo?si=gg0B3zVPxk2ZfP-w' },
    { title: 'Setting a Sudoku with Scojo', ytid: 'UnfMHbahxnQ', embed: 'https://www.youtube.com/embed/UnfMHbahxnQ?si=FGRcS9C7qv6vowqM' },
    { title: 'Solving Pairdoku with Jonn and Wessel', ytid: 'sntMX5I7seM', embed: 'https://www.youtube.com/embed/sntMX5I7seM?si=ofuT7lw3CuI2nS3o' },
    { title: 'Learning Tapa with Jonn, Wessel, and Caren', ytid: 'E5PH_G2gHZw', embed: 'https://www.youtube.com/embed/E5PH_G2gHZw?si=-04bQQSO6haiado0' },
    { title: 'Niverio\'s Hour of Speed', ytid: '0pB_9a_yyNA', embed: 'https://www.youtube.com/embed/0pB_9a_yyNA?si=XFDXQn822gBRxaF9' },
    { title: 'Solving DiMono\'s \'Why Am I Like This?\'', ytid: 'C7O1PEk47AE', embed: 'https://www.youtube.com/embed/C7O1PEk47AE?si=eEtw2PNAM_uql-Br' },
    { title: 'Designing a Computer Solver', ytid: 'WmST7Wha_zY', embed: 'https://www.youtube.com/embed/WmST7Wha_zY?si=UAkkgFHFP6Y1dad0' },
    { title: 'Puzzle Roast', ytid: '1muSRyZr--U', embed: 'https://www.youtube.com/embed/1muSRyZr--U?si=zOu-81TFymmgbuoZ' },
    { title: 'Speed Solving with Qodec', ytid: 'lrLfWFYKI5M', embed: 'https://www.youtube.com/embed/lrLfWFYKI5M?si=YWbfhdaBBvZUNt0u' },
    { title: 'Finale with Zetamath and Tristan', ytid: 'YHZ_h8SoviA', embed: 'https://www.youtube.com/embed/YHZ_h8SoviA?si=vL73INZalfgySATM' },
];

export default function Livestream() {
    return <Base>
        <h1>2023 Livestream Fundraiser</h1>

        <p>Check out the highlights of our first-ever fundraiser on our YouTube channel:</p>

        { videos.map(video => <>
            <a href={ 'https://youtu.be/' + video.ytid } rel="noreferrer" target="_blank">
                <img src={logoYouTube} alt="[YT]" style={{ height: '0.7em', marginRight: '0.5em' }} />
                {video.title}
            </a><br/>
        </>)}

        <p>We also owe some special thanks to the many people who helped with the 2023 SudokuCon fundraiser! Here are some shoutouts to the many capable and generous people who helped out, and some links to their additional puzzle projects:</p>

        <h2>Puzzles</h2>

        <p>Many talented setters have created puzzles for SudokuCon already, and we haven't been able to share all of them on stream. Download the <a href="./SudokuCon_Puzzle_Book_2023.pdf" target="_blank">SudokuCon puzzle book</a> for free, containing over 100 puzzles from dozens of setters.</p>

        <h2>Sponsors</h2>

        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5%' }}>
            <div style={{ flex: '1 1 0', minWidth: '10em' }}>
                <a href="https://dimono.ca" target="_blank" rel="noreferrer">
                    <img src={ad_dimono} width='100%' />
                </a>
            </div>
            <div style={{ flex: '1 1 0', minWidth: '10em' }}>
                <a href="http://artisanalsudoku.com" target="_blank" rel="noreferrer">
                    <img src={ad_james} width='100%' />
                </a>
            </div>
        </div>

        <h2>Tech</h2>

        Gliperal
        <br />Rangsk &nbsp;&#9679;&nbsp; <a href="" target="_blank" rel="noreferrer">https://youtube.com/@rangsk</a>
        <br />Sven Neumann &nbsp;&#9679;&nbsp; <a href="https://svencodes.com/" target="_blank" rel="noreferrer">svencodes.com</a>
        <br />Whirlpion

        <h2>Streamers</h2>

        <div style={{ wordBreak: 'break-word' }}>
            Bill Murphy &nbsp;&#9679;&nbsp; <a href="https://patreon.com/wwmsudoku" target="_blank" rel="noreferrer">patreon.com/wwmsudoku</a>
            <br />Carabet &nbsp;&#9679;&nbsp; <a href="https://twitch.tv/sudokuconcarabet" target="_blank" rel="noreferrer">twitch.tv/sudokuconcarabet</a>
            <br />Clover &nbsp;&#9679;&nbsp; <a href="https://patreon.com/sudokuclover" target="_blank" rel="noreferrer">patreon.com/sudokuclover</a>
            <br />FullDeck & Missingafewcards &nbsp;&#9679;&nbsp; <a href="https://missingdeck.net/" target="_blank" rel="noreferrer">missingdeck.net</a>
            <br />James Sinclair &nbsp;&#9679;&nbsp; <a href="https://artisanalsudoku.substack.com/" target="_blank" rel="noreferrer">artisanalsudoku.substack.com</a>
            <br />Jonn
            <br />Kodra &nbsp;&#9679;&nbsp; <a href="https://twitch.tv/kodra22" target="_blank" rel="noreferrer">twitch.tv/kodra22</a>
            <br />Memeristor &nbsp;&#9679;&nbsp; <a href="https://www.youtube.com/@memeristor" target="_blank" rel="noreferrer">www.youtube.com/@memeristor</a>
            <br />Niverio &nbsp;&#9679;&nbsp; <a href="https://twitch.tv/niverio24" target="_blank" rel="noreferrer">twitch.tv/niverio24</a>
            <br />Philip Newman &nbsp;&#9679;&nbsp; <a href="https://patreon.com/sudokutheory" target="_blank" rel="noreferrer">patreon.com/sudokutheory</a>
            <br />Prasanna Seshadri &nbsp;&#9679;&nbsp; <a href="https://logicmastersindia.com/expo/" target="_blank" rel="noreferrer">logicmastersindia.com/expo</a>
            <br />Qodec &nbsp;&#9679;&nbsp; <a href="https://twitch.tv/puzzleqodec" target="_blank" rel="noreferrer">twitch.tv/puzzleqodec</a>
            <br />Scojo
            <br />Sven Neumann &nbsp;&#9679;&nbsp; <a href="https://svencodes.com/" target="_blank" rel="noreferrer">svencodes.com</a>
            <br />Tantan Dai
            <br />The Bear That Wasn't
            <br />Wessel Strijkstra &nbsp;&#9679;&nbsp; <a href="https://puzz.link/db/?type=kouchoku&via=WStrijkstra" target="_blank" rel="noreferrer">puzz.link/db/?type=kouchoku&via=WStrijkstra</a>
            <br />Zetamath & Tristan &nbsp;&#9679;&nbsp; <a href="https://youtube.com/@zetamathdoespuzzles" target="_blank" rel="noreferrer">youtube.com/@zetamathdoespuzzles</a>

            <h2>Content Contributors</h2>

            99% Sneaky
            <br />Akash Doulani &nbsp;&#9679;&nbsp; <a href="https://akashdoulani.blogspot.com/" target="_blank" rel="noreferrer">akashdoulani.blogspot.com</a>
            <br />crusader175 &nbsp;&#9679;&nbsp; <a href="https://www.youtube.com/@CrusaderPuzzle" target="_blank" rel="noreferrer">www.youtube.com/@CrusaderPuzzle</a>
            <br />Craig Kasper
            <br />KenGlue
            <br />Malrog &nbsp;&#9679;&nbsp; <a href="https://logic-masters.de/Raetselportal/Benutzer/allgemein.php?name=Malrog" target="_blank" rel="noreferrer">logic-masters.de/Raetselportal/Benutzer/allgemein.php?name=Malrog</a>
            <br />Microstudy &nbsp;&#9679;&nbsp; <a href="https://drive.google.com/file/d/1tOAQMy8Wl8DFUe5X5rVgFpr_I1g9ohUA" target="_blank" rel="noreferrer">drive.google.com/file/d/1tOAQMy8Wl8DFUe5X5rVgFpr_I1g9ohUA</a>
            <br />rockratzero
            <br />shye &nbsp;&#9679;&nbsp; Contact shye (@shyeheya) on Discord for custom puzzle commissions!
            <br />thelukeskywalker2
            <br />thetearex &nbsp;&#9679;&nbsp; <a href="https://tinyurl.com/233y3r45" target="_blank" rel="noreferrer">tinyurl.com/233y3r45</a>
        </div>
    </Base>
}
