import format from 'date-fns/format';

function localTime(unixTime) {
    const date = new Date(unixTime);
    return `${format(date, 'EEEE, LLLL d, yyyy')}, at ${format(date, 'p')}`;
}

export function getDateOnly(date, offset) {
    let d = new Date(date);
    if (offset)
        d = new Date(d.getTime() + offset);
    const year = offset ? d.getUTCFullYear() : d.getFullYear();
    const month = offset ? d.getUTCMonth() : d.getMonth();
    const day = offset ? d.getUTCDate() : d.getDate();
    return new Date(year, month, day);
}

// TODO won't work with offset = 0
export function getTimeOfDay(date, offset) {
    date = new Date(date);
    if (offset)
        date = new Date(date.getTime() + offset);
    let h = offset ? date.getUTCHours() : date.getHours();
    const am = h < 12;
    h %= 12;
    if (h === 0)
        h = 12;
    const m = offset ? date.getUTCMinutes() : date.getMinutes();
    return `${h}:${String(m).padStart(2, '0')} ${am ? 'AM' : 'PM'}`;
}
