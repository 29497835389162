import { TwitchPlayer } from 'react-twitch-embed';

import Base from '../components/Base.js';
import styles from './Home.module.css';
import logoYouTube from '../images/socials/yt_icon_rgb.png';

import ad_dimono from '../images/sponsors/TidingsOfMinos.png';
import ad_james from '../images/sponsors/ArtisanalSudoku.png';
import ad_timberlake from '../images/sponsors/SmartHobbies.png';

const videos = [
    { ytid: 'iX1WSty9jX8', title: 'Introduction and Panel Discussion' },
    { ytid: 'yGTsuZBqytI', title: 'Puzzle Jeopardy' },
    { ytid: 'hzjMI0FeTFk', title: 'Yoshi Anpuku, president of Nikoli' },
    { ytid: 'eihwgbgtilE', title: 'Blindfolded Sudoku' },
    { ytid: 'E7IFOkqijes', title: 'Caterpillar Continued' },
    { ytid: 'HpFE1m9wRig', title: 'Celeste Sudoku Speedrun' },
    { ytid: 'FMR7AV3j9kc', title: 'Kodra Solves Sudoku' },
    { ytid: 'lpNVDPUJQes', title: 'Tag Team 6x6 Sudoku Solving' },
    { ytid: '5QOXtrzKD0k', title: 'Pachyderm Pencil Puzzle Palooza' },
    { ytid: 'Aq-0WIATK84', title: 'Sudoku University' },
    { ytid: 'cflBEFjKoMw', title: 'Sunday Solving with Zetamath' },
];

export default function Livestream() {
    return <Base>
        <h1>2024 24-Hour Livestream</h1>

        <h2>Sponsored by</h2>
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '5%' }}>
            <div style={{ flex: '1 1 0', minWidth: '15em', maxWidth: '25em' }}>
                <a href="https://dimono.ca" target="_blank" rel="noreferrer">
                    <img src={ad_dimono} alt="The Tidings of Minos" width='100%' />
                </a>
            </div>
            <div style={{ flex: '1 1 0', minWidth: '15em', maxWidth: '25em' }}>
                <a href="http://artisanalsudoku.com" target="_blank" rel="noreferrer">
                    <img src={ad_james} alt="Artisanal Sudoku" width='100%' />
                </a>
            </div>
            <div style={{ flex: '1 1 0', minWidth: '15em', maxWidth: '25em' }}>
                <a href="https://www.youtube.com/@SmartHobbies" target="_blank" rel="noreferrer">
                    <img src={ad_timberlake} alt="Artisanal Sudoku" width='100%' />
                </a>
            </div>
        </div>

        <h2>Highlights</h2>
        <p>Check out the highlights of our ticket-sales-opening livestream on our YouTube channel:</p>
        {videos.map(video => <div key = { video.ytid}>
            <a href={ 'https://youtu.be/' + video.ytid } rel="noreferrer" target="_blank">
                <img src={logoYouTube} alt="[YT]" style={{ height: '0.7em', marginRight: '0.5em' }} />
                {video.title}
            </a><br/>
        </div>)}

        <h2>Streamers</h2>
        <p>Thank you to all of our 2024 24-hour streamers, in order of appearance:</p>
        <ul>
            <li>Tallcat</li>
            <li><a href="http://artisanalsudoku.com" target="_blank" rel="noreferrer">James Sinclair</a></li>
            <li>the_cogito</li>
            <li><a href="https://www.youtube.com/@memeristor" target="_blank" rel="noreferrer">Memeristor</a></li>
            <li><a href="https://patreon.com/sudokuclover" target="_blank" rel="noreferrer">Clover</a></li>
            <li>Gliperal</li>
            <li><a href="https://twitch.tv/kodra22" target="_blank" rel="noreferrer">Kodra</a></li>
            <li>Bear</li>
            <li><a href="https://twitch.tv/niverio24" target="_blank" rel="noreferrer">Niverio</a></li>
            <li>Mr. Don</li>
            <li><a href="https://www.twitch.tv/glum_hippo" target="_blank" rel="noreferrer">Glum Hippo</a></li>
            <li><a href="https://missingdeck.net/" target="_blank" rel="noreferrer">FullDeck, Missingafewcards, and students</a></li>
            <li><a href="https://youtube.com/@zetamathdoespuzzles" target="_blank" rel="noreferrer">Zetamath</a></li>
        </ul>

        <h2>Puzzles</h2>
        <p>Puzzles solved on the 24-hour stream appear below:</p>
        <ul>
            <li>Caterdokupillar: puzzles from the Caterdokupillar project, managed by Memeristor, were solved during two of the blocks. Check out <a href="https://theasylm.github.io/caterdokupillar-help/" target="_blank" rel="noreferrer">The Official Caterdokupillar Help Page</a> for more information and solving links for these puzzles!</li>
            <p></p>
            <li>Blindfolded solving: these puzzles were solved blindfolded by Clover and Gliperal.
                <ul>
                    <li><a href="https://sudokupad.app/r151y200i6" target="_blank" rel="noreferrer">"Classic Sudoku" by clover!</a></li>
                    <li><a href="https://sudokupad.app/5iz142i692" target="_blank" rel="noreferrer">"Thermo Sudoku" by clover!</a></li>
                    <li><a href="https://sudokupad.app/p73irx2493" target="_blank" rel="noreferrer">"Classic Sudoku" by Gliperal</a></li>
                    <li><a href="https://sudokupad.app/67x94ckaw4" target="_blank" rel="noreferrer">"Skyscraper Sudoku" by clover!</a></li>
                </ul>
            </li>
            <p></p>
            <li>Celeste speedrun: these puzzles were solved by Kodra during the Celeste Sudoku% speedrun.
                <ul>
                    <li><a href="https://sudokupad.app/philip-newman/20240906-whirlpool" target="_blank" rel="noreferrer">"2024-09-06: Whirlpool" by Philip Newman</a></li>
                    <li><a href="https://sudokupad.app/b1gwgl3hmq" target="_blank" rel="noreferrer">"Big Goose Energy" by Bill Murphy</a></li>
                    <li><a href="https://sudokupad.app/e5wf6mvsv8" target="_blank" rel="noreferrer">"Killer Sudoku" by clover!</a></li>
                    <li><a href="https://sudokupad.app/obehcxr5h6" target="_blank" rel="noreferrer">"Special Edition: GW" by clover!</a></li>
                    <li><a href="https://sudokupad.app/t91if3lwol" target="_blank" rel="noreferrer">"Skyscraper" by Bill Murphy</a></li>
                    <li><a href="https://sudokupad.app/philip-newman/20240912-fall-in-texas" target="_blank" rel="noreferrer">"2024-09-12: Fall in Texas" by Philip Newman</a></li>
                    <li><a href="https://sudokupad.app/uqvv06s42j" target="_blank" rel="noreferrer">"Knapp Daneban Killer" by Bill Murphy</a></li>
                </ul>
                Kodra also solved <a href="https://sudokupad.app/cu1w8uidlc" target="_blank" rel="noreferrer">"Stuzzle" by Palfly Kampling</a>
            </li>
            <p></p>
            <li>Pencil Puzzle Palooza: These pencil puzzles were solved by Glum Hippo.
                <ul>
                    <li><a href="https://puzz.link/p?vslither/6/6/k26chcg268e891cgc" target="_blank" rel="noreferrer">Vertex Slitherlink by Menderbug</a></li>
                    <li><a href="https://puzz.link/p?barns/11/11/002100020ii2k2l0l0501000018g000a80400800005k04000002a00o0000000000g20" target="_blank" rel="noreferrer">Barns by vanillaice</a></li>
                    <li><a href="https://puzz.link/p?yinyang/10/10/k26i0022kk2206i86260k260k680006k6i" target="_blank" rel="noreferrer">Yin-Yang by PinkHoodie</a></li>
                    <li><a href="https://puzz.link/p?sudoku/9/9/h34j9g2h5h8g1j67j56j2g4h7h6g3j89j45j1g3h6h9g2j78h" target="_blank" rel="noreferrer">Sudoku by MatusDemiger</a></li>
                    <li><a href="https://puzz.link/p?geradeweg/10/10/r2j5i4l3k12m3h3l4h4m12k2l4i4j2r" target="_blank" rel="noreferrer">Geradeweg by Menderbug</a></li>
                    <li><a href="https://puzz.link/p?gokigen/8/8/ma988dh7ch3cg2diei3762dgddhdg32dn" target="_blank" rel="noreferrer">Slant by ゲッソー</a></li>
                    <li><a href="https://puzz.link/p?akari/10/10/.g5.6.ganbl.q1.bi..k10bo.nbn.g5.5.ga" target="_blank" rel="noreferrer">Akari by びーろく</a></li>
                    <li><a href="https://puzz.link/p?kinkonkan/8/8/0c7lvvvqu300vpu1gf0o7pvgCdEGaAGbEDaCaBaBaFAdDbFa28232812222312" target="_blank" rel="noreferrer">Kin-Kon-Kan by かしゆさかな</a></li>
                    <li><a href="https://puzz.link/p?kouchoku/11/10/w..4.0.d9998hello95.2world93r91.90r2.1h0.1" target="_blank" rel="noreferrer">Kouchoku by Wessel Strijkstra</a></li>
                </ul>
                Glum also solved <a href="https://sudokupad.app/r4ez0780qa" target="_blank" rel="noreferrer">"They hear me deeting, they doot it" by Palfly Kampling</a>
            </li>
            <p></p>
            <li>Sudoku University: These puzzles were set by FullDeck and Missingafewcards' students, and many of them were solved on stream during the Sudoku University block.
                <ul>
                    <li><a href="https://sudokupad.app/6yx7jfoh3j" target="_blank" rel="noreferrer">"Add noodles please" by xiaobudian</a></li>
                    <li><a href="https://sudokupad.app/a7nbvtsw78" target="_blank" rel="noreferrer">"add noodles please 2.0" by xiaobudian</a></li>
                    <li><a href="https://sudokupad.app/dflvoybx7q" target="_blank" rel="noreferrer">"Empire's Backbone" by KingFish & Qualter</a></li>
                    <li><a href="https://sudokupad.app/oae3gfosr1" target="_blank" rel="noreferrer">"Mid-Mod" by El Presidente & Silver Lace</a></li>
                    <li><a href="https://sudokupad.app/g5aeilkduu" target="_blank" rel="noreferrer">"BModW" by Orion and Qualter</a></li>
                    <li><a href="https://sudokupad.app/fwg95reg6m" target="_blank" rel="noreferrer">"Good Luck" by Raspberry Dime, Hinsley, Karl, 591</a></li>
                    <li><a href="https://sudokupad.app/706weyq1g4" target="_blank" rel="noreferrer">"Bunny" by Conejito</a></li>
                    <li><a href="https://sudokupad.app/8zffj27a8l" target="_blank" rel="noreferrer">"Its the remix" by xiaobudian</a></li>
                    <li><a href="https://sudokupad.app/naek4cbf72" target="_blank" rel="noreferrer">"9 O'Clock" by Qualter</a></li>
                    <li><a href="https://sudokupad.app/t0f2s5ycn6" target="_blank" rel="noreferrer">"Tire Marks" by KingFish & LadyGrey</a></li>
                    <li><a href="https://sudokupad.app/8r4difg9r1" target="_blank" rel="noreferrer">"Six of Diamonds" by FullDeck and Missing a Few Cards</a></li>
                    <li><a href="https://sudokupad.app/c2fa6axo3m" target="_blank" rel="noreferrer">"Seven of Diamonds" by FullDeck and Missing a Few Cards</a></li>
                    <li><a href="https://sudokupad.app/thfor7wuk1" target="_blank" rel="noreferrer">"The Silent Shaft" by FullDeck and Missing a Few Cards</a></li>
                </ul>
            </li>
            <p></p>
            <li>Sunday Solving: These puzzles were solved by Zetamath during the final block.
                <ul>
                    <li><a href="https://tinyurl.com/56tsv8d8" target="_blank" rel="noreferrer">"Quadruple #2" by Blashyrkh</a></li>
                    <li><a href="https://tinyurl.com/yhfthk2t" target="_blank" rel="noreferrer">"Boundaries" by Playmaker6174</a></li>
                    <li><a href="https://sudokupad.app/cq0i9oc3qw" target="_blank" rel="noreferrer">"Architecture" by Scruffamudda</a></li>
                    <li><a href="https://sudokupad.app/36pn33l5c6" target="_blank" rel="noreferrer">"Irregular XVs" by sudoku_explorer</a></li>
                    <li><a href="https://sudokupad.app/it4gfqemym" target="_blank" rel="noreferrer">"Renban and Black Dots? #1" by Palfly Kampling</a></li>
                    <li><a href="https://sudokupad.app/5svm0ffqfg" target="_blank" rel="noreferrer">"Mini Snack Crossing" by Nell Gwyn</a></li>
                </ul>
            </li>
        </ul>

        <h2>Tech</h2>

        <a href="https://patreon.com/sudokuclover" target="_blank" rel="noreferrer">Clover</a>
        <br />Gliperal
        <br /><a href="https://svencodes.com/" target="_blank" rel="noreferrer">Sven Neumann</a>
        <br />Whirlpion
    </Base>
}
